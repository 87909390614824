<template>
	<!-- <div class="dashboard-main"> -->
	<v-row>
		<v-col cols="12" class="px-0">
			<Viewdetails></Viewdetails>
		</v-col>
		<v-col cols="12" md="12" lg="12" class="py-0">
			<v-row style="height: calc(100vh - 170px); overflow-y: auto">
				<v-col cols="12" md="12" lg="12">
					<ProjectStatus></ProjectStatus>
				</v-col>
				<v-col cols="12" md="12">
					<TotalActivity></TotalActivity>
				</v-col>
			</v-row>
		</v-col>
		<!-- <v-col cols="6" md="4">
				<OverAllStats></OverAllStats>
			</v-col>
			<v-col cols="6" md="4">
				<div id="chart"></div>
			</v-col>
			<v-col cols="6" md="4">
				<CalenderEvent></CalenderEvent>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<ProjectStatus></ProjectStatus>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<MyActivity></MyActivity>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<TotalUsers></TotalUsers>
			</v-col>
			<v-col cols="12" md="6  ">
				<TotalRevenue></TotalRevenue>
			</v-col>
			<v-col cols="12" md="6  ">
				<OrderState></OrderState>
			</v-col>
			<v-col cols="12" md="12">
				<ProjectOverview></ProjectOverview>
			</v-col>
			<v-col cols="12" md="12">
				<SalesAnalytics></SalesAnalytics>
			</v-col>
			<v-col cols="12" md="6">
				<Revenue></Revenue>
			</v-col>
			<v-col cols="12" md="6">
				<Revenue2></Revenue2>
			</v-col>
			<v-col cols="12" md="6">
				<TotalActivity></TotalActivity>
			</v-col>
			<v-col cols="12" md="6">
				<TeamMember></TeamMember>
			</v-col>
			<v-col cols="12" md="12">
				<Permission></Permission>
			</v-col> -->
	</v-row>
	<!-- </div> -->
</template>
<script>
import ProjectStatus from "@/view/module/components/SEODash-ProjectStatus";
import TotalActivity from "@/view/module/components/SeoDash-TotalActivity";
// import TotalUsers from "@/view/pages/dashboard/components/TotalUsers";
// import TotalRevenue from "@/view/pages/dashboard/components/TotalRevenue";
// import OrderState from "@/view/pages/dashboard/components/OrderState";
// import ProjectOverview from "@/view/pages/dashboard/components/ProjectOverview";
// import SalesAnalytics from "@/view/pages/dashboard/components/SalesAnalytics";
// import Revenue from "@/view/pages/dashboard/components/Revenue";
// import Revenue2 from "@/view/pages/dashboard/components/Revenue2";
// import DealStatus from "@/view/pages/dashboard/components/DealStatus";
import Viewdetails from "@/view/module/components/SEODash-Viewdetails";
// import TeamMember from "@/view/pages/dashboard/components/TeamMember.vue";
// import Permission from "@/view/module/permission/Permission";
// import MyActivity from "@/view/pages/dashboard/components/MyActivity";
// import OverAllStats from "@/view/pages/dashboard/components/OverAllStats";
// import CalenderEvent from "@/view/pages/dashboard/components/CalenderEvent.vue";
export default {
	name: "Dashboard",
	data() {
		return {};
	},
	components: {
		Viewdetails,
		ProjectStatus,
		TotalActivity,
		// TotalUsers,
		// TotalRevenue,
		// OrderState,
		// ProjectOverview,
		// SalesAnalytics,
		// Revenue,
		// Revenue2,
		// DealStatus,
		// TeamMember,
		// Permission,
		// MyActivity,
		// OverAllStats,
		// CalenderEvent,
	},
};
</script>
