<template>
	<div class="d-flex align-center">
		<div class="project-status-dashboard card-shadow mr-3" style="min-width: 25vw">
			<v-layout class="px-3 py-2 border-bottom">
				<div class="my-auto font-level-3-bold fw-600">Highest Work Project</div>
				<v-spacer></v-spacer>
				<v-select
					class="cm-menu-select elevation-0 red--text"
					v-model="project_status"
					:items="projectStatusList"
					hide-details
					dense
					solo
				>
					<template v-slot:selection="{ item }">
						<span class="blue--text font-level-3-bold">{{ item }}</span>
					</template>
				</v-select>
			</v-layout>
			<div class="pa-3">
				<table width="100%">
					<thead>
						<tr>
							<th width="50%" class="fw-600">Name</th>
							<th width="25%" class="text-center fw-600">ON Page</th>
							<th width="25%" class="text-center fw-600">OFF Page</th>
						</tr>
					</thead>
					<tbody>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 1</td>
							<td class="font-weight-medium text-center">25</td>
							<td class="font-weight-medium text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 2</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 3</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 4</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem">
							<td class="font-weight-medium">Project 5</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
					</tbody>
				</table>
				<div class="mt-2 d-flex flex-row-reverse">
					<a href="#" style="text-decoration: underline">View more</a>
				</div>
			</div>
		</div>
		<div class="project-status-dashboard card-shadow" style="min-width: 25vw">
			<v-layout class="px-3 py-2 border-bottom">
				<div class="my-auto font-level-3-bold fw-600">Lowest Work Project</div>
				<v-spacer></v-spacer>
				<v-select
					class="cm-menu-select elevation-0 red--text"
					v-model="project_status"
					:items="projectStatusList"
					hide-details
					dense
					solo
				>
					<template v-slot:selection="{ item }">
						<span class="blue--text font-level-3-bold">{{ item }}</span>
					</template>
				</v-select>
			</v-layout>
			<div class="pa-3">
				<table width="100%">
					<thead>
						<tr>
							<th width="50%" class="fw-600">Name</th>
							<th width="25%" class="text-center fw-600">ON Page</th>
							<th width="25%" class="text-center fw-600">OFF Page</th>
						</tr>
					</thead>
					<tbody>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 1</td>
							<td class="font-weight-medium text-center">25</td>
							<td class="font-weight-medium text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 2</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 3</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem" class="border-bottom">
							<td class="font-weight-medium">Project 4</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
						<tr style="height: 2rem">
							<td class="font-weight-medium">Project 5</td>
							<td class="font-weight-medium text-center text-center">25</td>
							<td class="font-weight-medium text-center text-center">25</td>
						</tr>
					</tbody>
				</table>

				<div class="mt-2 d-flex flex-row-reverse">
					<a href="#" style="text-decoration: underline">View more</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_status: "All Time",
			projectStatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [44, 55, 77, 15],
			chartOptions: {
				chart: {
					width: 280,
					type: "donut",
				},
				legend: {
					position: "bottom",
					show: false,
				},
			},
			chartData: [
				{
					status_color: "red",
					status: "Completed",
					project_no: 12,
					project_time: "15",
				},
				{
					status_color: "blue",
					status: "inProgress",
					project_no: 32,
					project_time: "45 ",
				},
				{
					status_color: "orange",
					status: "Yet to start",
					project_no: 50,
					project_time: "~135 ",
				},
				{
					status_color: "red",
					status: "Cancelled   ",
					project_no: 89,
					project_time: "~90",
				},
			],
		};
	},
};
</script>
